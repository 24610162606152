const headerServData: any = [
  {
    header: "Restructuring Advice",
    text: "Restructuring your commercial property lease can deliver unparalleled cost savings for businesses of all sizes. ",
    link: "/services/restructuring-advice",
    num: "one",
  },
  {
    header: "Acquisitions",
    text: "We focus on the strategic growth of companies, ensuring that it is in line with the overall vision of the company. From owning your own property, to acquisition of new strategic leasehold locations, this is just yet another one of the specialist services we advise on. ",
    link: "/services/acquisitions",
    num: "two",
  },
  {
    header: "Lease Renewals",
    text: "If your lease is approaching renewal, don�t miss the opportunity to negotiate more favourable terms, or even reduce your rent. ",
    link: "/services/lease-renewals",
    num: "three",
  },
  {
    header: "Lease Surrenders",
    text: "If your business is tied into a lease it no longer requires, Cedar Dean can help.",
    link: "/services/lease-surrenders",
    num: "four",
  },
  {
    header: "Rent Reviews",
    text: "Rent reviews can be problematic for commercial tenants. ",
    link: "/services/rent-reviews",
    num: "five",
  },
  {
    header: "Rent Reductions",
    text: "There are various opportunities for rent reductions on your commercial leasehold properties. ",
    link: "/services/rent-reductions",
    num: "six",
  },
  {
    header: "Strategic Lease Management",
    text: "If you have a portfolio of leasehold properties, you could benefit from talking to us.",
    link: "/services/strategic-lease-management",
    num: "seven",
  },
  {
    header: "View All Services",
    text: "Not sure which service you are interested in yet? No worry, check out all of our services here!",
    link: "/services",
    num: "eight",
  },
];

export default headerServData;

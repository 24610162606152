import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import { Left, Right, Text } from "./styles";

import { useInView } from "react-intersection-observer";

const Five = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "RENT REVIEWS",
    img: servImg.sFiveBig,
    imgM: servImg.fiveM,

    quote: {
      img: null,
      text: `“Cedar Dean were a pleasure to work with. Not only wre they highly professional, but they achieved outstanding results in a short time period.”`,
      from: (
        <h4>
          Richard Morris, Managing Director - <b>Tortilla Mexican Grill</b>
        </h4>
      ),
    },

    body: [
      "Rent reviews can be problematic for commercial tenants. They give landlords an opportunity to revise rents upwards or maintain their level. Although rents won’t decrease as a result of a rent, rent reviews can prove to be very problematic for everyone, but they can be a particularly major issue for commercial tenants and leaseholders. Unaffordable and unfair rent hikes can have untold financial impact on UK businesses, and some businesses find themselves in the uncomfortable position of wanting to avoid the rent hikes but can’t out of fear of conflict or escalation. ",
      "Thankfully, Cedar Dean is here to help: working with us can strengthen your position against rising rent costs and price increases, with our expert team analysing your competitors and neighbours to ensure any rent revisions remain comparable, fair, and affordable for your business.",
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>{data.header}</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>

        <BannerInner>
          <p>{data.quote.text}</p>

          {data.quote.from}
        </BannerInner>
      </Banner>
      <Text inView={inView} ref={ref}>
        <Left>
          <p>{data.body[0]}</p>
          <p>{data.body[1]}</p>
        </Left>
        <Right>
          <p>{data.body[2]}</p>

          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
    </Container>
  );
};

export default Five;

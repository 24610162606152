import { useNavigate } from "react-router-dom";
import img from "../../../assets";
import servImg from "../../../assets/services";
import IndValues from "../../../components/individualServ/values";
import useWindowSize from "../../../utils/getWindowSize";
import {
  Banner,
  BannerBg,
  BannerInner,
  ButtonCont,
  Container,
  Header,
  RedBtn,
} from "../styles";
import {
  Left,
  RedCont,
  Right,
  Text,
  WhatIsCont,
  WhatisLeft,
  WhatisRight,
} from "./styles";

import { useInView } from "react-intersection-observer";

const One = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const data = {
    header: "RESTRUCTURING ADVICE",
    img: servImg.sOneBig,
    imgM: servImg.oneM,
    quote: {
      img: servImg.geox,
      text: ` “I strongly recommend Cedar Dean: Our group engaged them in the frame
        of a challenging restructuring project of our retail chain in the UK.
        Cedar Dean’s team is excellent in the deep understanding of business
        needs in a post-pandemic retail perspective and were extraordinary in
        the negotiations.”`,
      from: (
        <h4>
          Valentino Maffei, Director of Legal Affairs - <b>GEOX</b>
        </h4>
      ),
    },

    body: [
      "Restructuring your commercial property lease can deliver unparalleled cost savings for businesses of all sizes and across all industries.",
      "Commercial Leases can be restructured at any time - you don’t have to wait until your lease is up for renewal to negotiate changes: Cedar Dean is here to help provide expert advice and representation concerning restructuring. ",
      "We see this as something beneficial to tenants and landlords alike. We work exclusively with tenants, helping to bridge the communication gap with their landlords to achieve better lease terms for everyone involved.",
      "When you work with Cedar Dean, you’re working with the market leaders in commercial lease restructuring. With an exceptional track record of delivering positive results for the businesses we advise, Cedar ensures that your commercial lease suits you to perfection, with improved affordability and more flexible terms negotiated for all of our clients.",
    ],
  };
  const { ref: redContRef1, inView: redContInView1 } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  const { ref: redContRef2, inView: redContInView2 } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  const { ref, inView } = useInView({
    triggerOnce: true, // The animation will only trigger once
  });

  return (
    <Container>
      <Header>RESTRUCTURING ADVICE</Header>
      <Banner img={width! > 550 ? data.img : data.imgM}>
        <BannerBg></BannerBg>

        <BannerInner>
          <p>{data.quote.text}</p>
          <img src={data.quote.img} />
          {data.quote.from}
        </BannerInner>
      </Banner>
      <RedCont inView={redContInView1} del={"0"}>
        <p>Frequency of rental payments</p>
        <p ref={redContRef1}>Rent deferrals</p>
        <p>Renewal dates</p>
      </RedCont>
      <RedCont inView={redContInView2} del={"0.5"}>
        <p>Break options</p>
        <p ref={redContRef2}>Rent reductions</p>
        <p>Lease extensions</p>
      </RedCont>
      <Text inView={inView} del={""}>
        <Left>
          <p ref={ref}>{data.body[0]}</p>
          <p>{data.body[1]}</p>
          <p>{data.body[2]}</p>
        </Left>
        <Right>
          <p>{data.body[3]}</p>
          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </Right>
      </Text>
      <IndValues />
      <WhatIsCont>
        <WhatisLeft>
          <h1>WHAT IS LEASE RESTRUCTURING?</h1>
          <p>
            Lease restructuring is the term used to describe a review of
            commercial leasehold agreement terms. These terms cover things like:
          </p>
          <ul>
            <li>• Details of the property and its use under the lease</li>
            <li>• Details of the business renting the property</li>
            <li>
              • The tenancy term, whether it is a fixed term lease or whether it
              is renewable
            </li>
            <li>• How much the rent is and how and when it should be paid</li>
            <li>
              • Information on details like sublets, notice required for
              termination, insurance etc.
            </li>
          </ul>
          <p>
            Restructuring a lease can involve negotiating fresh terms for any of
            these issues, which can improve flexibility and reduce operating
            costs for the tenant. However, it can also improve return on
            investment for landlords and/or secure a more sustainable lease with
            a reliable tenant.
          </p>
          <p>
            We can advise on any aspect of lease restructuring, improving your
            chances of getting a favourable outcome for your business.
          </p>
          <ButtonCont onClick={() => navigate("/contact")}>
            <RedBtn>
              <span>Get In Touch</span>
            </RedBtn>
            <div>
              <img src={img.rightArrow} alt="rightArrow" />
            </div>
          </ButtonCont>
        </WhatisLeft>
        <WhatisRight>
          <img src={servImg.threeM} />
        </WhatisRight>
      </WhatIsCont>
    </Container>
  );
};

export default One;
